import React from "react";
import { AuthLogoutRequest, useLogoutMutation } from "../auth/authApi";
import { useAppSelector } from "../../app/hooks";
import Nav from "./Navbar.elements";
import config from "../../app/config.json";

const AppNavbar = (): JSX.Element => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);
  const auth = useAppSelector((state) => state.auth);
  const [logout] = useLogoutMutation();
  const logoutDetails: AuthLogoutRequest = {
    refresh: auth.refresh_token,
  };
  const handleLogout = () => {
    logout(logoutDetails);
  };

  const handleNavbarOpen = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <Nav>
      <Nav.Brand linkTo="/" wideWording={`${config.project.displayName}`} />
      <Nav.MenuBtn handleNavMenuOpen={handleNavbarOpen} />
      <Nav.Menu navbarOpen={navbarOpen}>
        <Nav.LinkCntnr>
          <Nav.Link
            linkTo={config.urls.home.url}
            linkDisplay={config.urls.home.displayName}
          />
          {isLoggedIn && user ? (
            <Nav.Link
              linkTo={config.urls.user.workspace.url}
              linkDisplay={config.urls.user.workspace.displayName}
            />
          ) : null}
        </Nav.LinkCntnr>

        {isLoggedIn && user ? (
          <Nav.ActionCntnr>
            <Nav.ActionDisplay user={user} />
            <Nav.ActionDropdown>
              <Nav.ActionLink
                linkTo={config.urls.home.url}
                linkDisplay={config.urls.home.displayName}
                firstItem
              />
              <Nav.ActionLink
                linkTo={config.urls.user.workspace.url}
                linkDisplay={config.urls.user.workspace.displayName}
              />
              <Nav.ActionLink
                linkTo={config.urls.home.url}
                linkDisplay={config.urls.auth.logout.displayName}
                handleFunction={handleLogout}
                lastItem
              />
            </Nav.ActionDropdown>
          </Nav.ActionCntnr>
        ) : null}
      </Nav.Menu>
    </Nav>
  );
};

export default AppNavbar;
