import { apiSlice } from "../api/apiSlice";

export interface Monitor {
  id: number;
  url: string;
  interval: number;
  periodic_task: number;
  auth_token: string;
}

export interface MonitorList {
  items_total: number;
  page_total: number;
  page: number;
  page_items: number;
  links: {
    next: string | null;
    previous: string | null;
  };
  data: Monitor[];
}

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addMonitor: builder.mutation<Monitor, Monitor>({
      query(monitor) {
        return {
          url: "uptime/monitors",
          credentials: "include",
          method: "POST",
          body: monitor,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Monitor", id: arg.id },
      ],
    }),
    getMonitor: builder.query<Monitor, string | undefined>({
      query(id) {
        return {
          url: `uptime/monitors/${id}`,
          method: "GET",
        };
      },
      providesTags(result, error, id) {
        return [{ type: "Monitor", id: id }];
      },
    }),
    getMonitors: builder.query<MonitorList, void>({
      query() {
        return {
          url: "uptime/monitors",
          credentials: "include",
          method: "GET",
        };
      },
      providesTags(result) {
        return result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Monitor" as const,
                id,
              })),
              { type: "Monitor", id: "LIST" },
            ]
          : ["Monitor"];
      },
    }),
    updateMonitor: builder.mutation<Monitor, Pick<Monitor, "id"> & Monitor>({
      query({ id, ...monitor }) {
        return {
          url: `uptime/monitors/${id}`,
          credentials: "include",
          method: "PUT",
          body: monitor,
        };
      },
      invalidatesTags(result, error, arg) {
        return [{ type: "Monitor", id: arg.id }];
      },
    }),
  }),
});

export const {
  useAddMonitorMutation,
  useGetMonitorQuery,
  useGetMonitorsQuery,
  useUpdateMonitorMutation,
} = extendedApiSlice;
