import React, { useState, useEffect } from "react";
import { object, string, number } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Components */
import MonitorDetailFormComponent from "../../utils/components/DetailForm";
import { LoadingScreen } from "../../utils/components/Common";

/* APIs - Utils */
import { Monitor, useUpdateMonitorMutation } from "./monitorApi";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";
import { usePrompt } from "../../utils/hooks";

const REGEX_URL = /^(http|https):\/\/[^ "]+$/;

type DetailFormProps = {
  data: Monitor;
};

const schema = object().shape({
  id: number().notRequired().nullable(),
  url: string()
    .matches(REGEX_URL, {
      message:
        "The URL is not valid. Please enter a valid URL starting with http:// or https://",
      excludeEmptyString: true,
    })
    .required(),
  interval: number().required(),
  periodic_task: number().notRequired().nullable(),
  auth_token: string().nullable(),
});

export const MonitorDetailForm = ({ data }: DetailFormProps): JSX.Element => {
  const [updatedData, setUpdatedData] = useState(data);
  const [updateMonitor, { isLoading, isSuccess, isError, error }] =
    useUpdateMonitorMutation();
  const [confirmDataModalOpen, setConfirmDataModalOpen] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<Monitor>({
    resolver: yupResolver(schema),
    defaultValues: updatedData,
    mode: "onChange",
  });

  const onSubmit = (updatedData: Monitor) => {
    updateMonitor(updatedData);
  };

  useEffect(() => {
    setConfirmDataModalOpen(false);
    if (isSuccess) {
      toastQuerySuccess("Successfully updated Monitor..");
      setUpdatedData(updatedData);
      reset(updatedData);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isSuccess, isError, error]);

  usePrompt({
    message:
      "Unsaved changes may be lost.\nAre you sure you want to leave the page?",
    when: isDirty,
  });

  return (
    <>
      {isLoading ? <LoadingScreen blackOut /> : null}
      <MonitorDetailFormComponent>
        <MonitorDetailFormComponent.Content onSubmit={handleSubmit(onSubmit)}>
          {confirmDataModalOpen ? (
            <MonitorDetailFormComponent.Modal
              setModalOpen={setConfirmDataModalOpen}
              header="Please verify changes to fields"
              body={`${JSON.stringify(Object.keys(dirtyFields), null, 4)}`}
            >
              <MonitorDetailFormComponent.Button
                type="button"
                txtColor="text-red-500"
                bgColor="bg-transparent"
                onClick={() => setConfirmDataModalOpen(false)}
              >
                CLOSE
              </MonitorDetailFormComponent.Button>
              <MonitorDetailFormComponent.Button
                type="submit"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
              >
                Save Changes
              </MonitorDetailFormComponent.Button>
            </MonitorDetailFormComponent.Modal>
          ) : null}
          <MonitorDetailFormComponent.Header>
            Monitor Information
          </MonitorDetailFormComponent.Header>
          <MonitorDetailFormComponent.Row>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                ID
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="id"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                URL
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="url"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                Interval
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="interval"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                Periodic Task
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="periodic_task"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                Auth Token
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="auth_token"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
          </MonitorDetailFormComponent.Row>
          <MonitorDetailFormComponent.Row>
            <MonitorDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-blue-500"
              bgHover="hover:bg-blue-600"
              onClick={() => setConfirmDataModalOpen(true)}
            >
              Update Monitor
            </MonitorDetailFormComponent.Button>
            <MonitorDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-slate-500"
              bgHover="hover:bg-slate-600"
              onClick={() => reset(updatedData)}
            >
              Reset
            </MonitorDetailFormComponent.Button>
          </MonitorDetailFormComponent.Row>
        </MonitorDetailFormComponent.Content>
      </MonitorDetailFormComponent>
    </>
  );
};
