import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { toast, Flip } from "react-toastify";

export function toastQueryError(
  error: FetchBaseQueryError | SerializedError | undefined
): void {
  const message_error = "Error Encountered: ";
  const message_unexpected_error = "An Unexpected Error has Occurred";

  if (error) {
    if ("data" in error) {
      toast.error(message_error + JSON.stringify(error.data), {
        position: "top-right",
      });
    } else {
      toast.error(message_unexpected_error, {
        position: "top-right",
      });
    }
  }
}

export function toastQuerySuccess(message: string): void {
  toast.success(message, {
    autoClose: 2000,
    position: "top-center",
    transition: Flip,
  });
}
