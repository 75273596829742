import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* Config */
// import config from "../../app/config.json";

/* Components */
import { WorkspaceJumbotron } from "./Workspace.elements";
import { Container } from "../../utils/components/Common";
import ReportListPanel from "../reports/ReportsList";
import MonitorListPanel from "../monitors/MonitorList";

/* APIs - Hooks - Utils */
import { useAppSelector } from "../../app/hooks";
// import { TabbedWorkspace } from "../../utils/components/Tabs";
// import { workspaceAPI } from "./workspaceApi";

const Workspace = (): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);

  return (
    <>
      {user && isLoggedIn ? (
        <div>
          <WorkspaceJumbotron user={user} isLoggedIn={isLoggedIn}>
            {/* <TabbedWorkspace workspaces={workspaceAPI} /> */}
            <Tabs>
              <Container padding="px-16 py-6">
                <TabList>
                  <Tab>Reports</Tab>
                  <Tab>Monitors</Tab>
                </TabList>
              </Container>
              <TabPanel>
                <ReportListPanel user={user} isLoggedIn={isLoggedIn} />
              </TabPanel>
              <TabPanel>
                <MonitorListPanel isLoggedIn={isLoggedIn} />
              </TabPanel>
            </Tabs>
          </WorkspaceJumbotron>
        </div>
      ) : null}
    </>
  );
};

export default Workspace;
