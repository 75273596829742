import React, { useEffect, useState } from "react";
import { IoReloadSharp } from "react-icons/io5";

/* Config */

/* Components */
import {
  Banner,
  Container,
  LoadingScreen,
  FormButton,
  ControlledSelect,
} from "../../utils/components/Common";
import { Table, FilterDropdown } from "../reports/Reports.table.elements";
import { ICellProps } from "../reports/Reports.table.elements";

/* APIs - Hooks - Utils */
import { User } from "../user/userApi";
import { useListReportsQuery } from "../reports/reportApi";
import { toastQueryError } from "../../utils/toasts";

type ReportListPanelProps = {
  user: User;
  isLoggedIn: boolean;
};

const ReportListPanel = ({
  user,
  isLoggedIn,
}: ReportListPanelProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageItems] = useState(10);
  const {
    data: reports,
    error,
    isFetching,
    isError,
    isLoading,
    refetch,
  } = useListReportsQuery({ page: page, page_size: pageSize });

  useEffect(() => {
    if (error) {
      toastQueryError(error);
    }
  }, [isError]);

  const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageItems(parseInt(e.target.value));
    setPage(1);
  };

  const showLastPage = () => {
    if (reports?.page_total) {
      if (reports.page_total > 1) {
        return (
          <FormButton
            txtColor="text-white"
            bgColor="bg-blue-800"
            type="button"
            onClick={() => setPage(reports.page_total)}
            disabled={reports?.page === reports?.page_total}
          >
            {">>"}
          </FormButton>
        );
      }
    }
  };

  const showFirstPage = () => {
    if (reports?.page_total) {
      if (reports.page_total != 1) {
        return (
          <FormButton
            txtColor="text-white"
            bgColor="bg-blue-800"
            type="button"
            onClick={() => setPage(1)}
            disabled={reports?.page === 1}
          >
            {"<<"}
          </FormButton>
        );
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Report ID",
        accessor: "id",
      },
      {
        Header: "Monitor",
        accessor: "monitor",
        // Custom cell to map monitor value to config temp monitor
      },
      {
        Header: "URL",
        accessor: "url",
        Filter: FilterDropdown,
        disableSortBy: true,
        Cell: ({ value }: ICellProps) => (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        ),
      },
      {
        Header: "Status Code",
        accessor: "status_code",
        Filter: FilterDropdown,
        disableSortBy: true,
      },
      {
        Header: "Is OK",
        accessor: "is_ok",
        Filter: FilterDropdown,
        disableSortBy: true,
        Cell: ({ value }: ICellProps) => <span>{`${value}`}</span>,
      },
      { Header: "Elapsed Total Seconds", accessor: "elapsed_total_seconds" },
      {
        Header: "Request Time",
        accessor: "request_time",
        /* Convert request_time to locale time */
        Cell: ({ value }: ICellProps) => (
          <span>{new Date(value).toLocaleString()}</span>
        ),
      },
      {
        Header: "Reason",
        accessor: "reason",
        Filter: FilterDropdown,
        disableSortBy: true,
        Cell: ({ value }: ICellProps) => <span>{`${value}`}</span>,
      },
      {
        Header: "Is Redirect",
        accessor: "is_redirect",
        Cell: ({ value }: ICellProps) => <span>{`${value}`}</span>,
      },
    ],
    []
  );

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {user && isLoggedIn ? (
        <Container padding="py-6">
          {processing ? <LoadingScreen /> : null}
          <Container padding="px-16 py-6">
            <div className="flex space-x-4">
              <FormButton
                txtColor="text-white"
                bgColor="bg-teal-500"
                type="button"
                onClick={() => refetch()}
              >
                <IoReloadSharp className="inline" />
              </FormButton>
              {showFirstPage()}
              <FormButton
                txtColor="text-white"
                bgColor="bg-blue-500"
                type="button"
                onClick={() => setPage((prevPage) => prevPage - 1)}
                disabled={reports?.page === 1}
              >
                {"<"}
              </FormButton>
              <p>
                Page <b>{reports?.page}</b> of <b>{reports?.page_total}</b>
              </p>
              <FormButton
                txtColor="text-white"
                bgColor="bg-blue-500"
                type="button"
                onClick={() => setPage((prevPage) => prevPage + 1)}
                disabled={reports?.page === reports?.page_total}
              >
                {">"}
              </FormButton>
              {showLastPage()}
              <ControlledSelect
                onChange={onPageSizeChange}
                selectType="pageItems"
              />
            </div>
          </Container>
          {reports ? <Table columns={columns} data={reports.data} /> : null}
        </Container>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default ReportListPanel;
