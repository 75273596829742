import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Config */
import config from "../../app/config.json";

/* Components */
import Login from "./LoginForm.elements";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";
import { LoadingScreen } from "../../utils/components/Common";

/* APIs - Utils*/
import {
  useMiddlewareLoginMutation,
  AuthMiddlewareLoginRequest,
} from "./authApi";

const LoginForm = (): JSX.Element => {
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(true);
  const [loginDetails, setLoginDetails] = useState<AuthMiddlewareLoginRequest>({
    username: "",
    password: "",
  });

  const [login, { isLoading, isSuccess, isError, error, data }] =
    useMiddlewareLoginMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(loginDetails);
  };

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginDetails({ ...loginDetails, username: e.target.value });
  };
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginDetails({ ...loginDetails, password: e.target.value });
  };

  useEffect(() => {
    if (isSuccess && data?.user) {
      toastQuerySuccess("Login successful..");
      navigate(config.urls.user.workspace.url);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
  }, [isLoading]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}
      {/* Login Component */}
      <Login>
        {/* Login Brand */}
        <Login.Brand
          brandName="AHI Webtools"
          brandLogoSrc="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
        />

        {/* Form */}
        <Login.Form handleSubmit={handleSubmit}>
          {/* Form Row */}
          <Login.FormRow>
            {/* Input Label */}
            <Login.InputLabel htmlFor="username" label="Username" />
            {/* Input */}
            <Login.Input
              inputType="username"
              onChange={onUsernameChange}
              required
            />
          </Login.FormRow>

          {/* Form Row */}
          <Login.FormRow>
            {/* Input Flex Container */}
            <Login.InputFlexContainer>
              {/* Input Label*/}
              <Login.InputLabel htmlFor="password" label="Password" />
              {/* Input Aside */}
              <Login.InputAside>
                <a
                  href="#"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </Login.InputAside>
            </Login.InputFlexContainer>
            {/* Input */}
            <Login.Input
              inputType="password"
              onChange={onPasswordChange}
              required
            />
          </Login.FormRow>

          {/* Form Submit */}
          <Login.FormSubmit wording="Submit" />

          {/* Form Footnote */}
          <Login.FormFootnote footNoteHeader="Not a member?">
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Start a 14 day free trial
            </a>
          </Login.FormFootnote>
        </Login.Form>
      </Login>
    </>
  );
};

export default LoginForm;
