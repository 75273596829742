import React from "react";

/* Components */
import { MonitorAddForm } from "./MonitorAddForm";

const MonitorAdd = (): JSX.Element => {
  return (
    <>
      <MonitorAddForm
        setAddMonitorModalOpen={() => {
          null;
        }}
        setAddedMonitor={() => {
          null;
        }}
      />
    </>
  );
};

export default MonitorAdd;
