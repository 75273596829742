import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import classNames from "classnames";
// import { formatDateTimeString } from "../datetime";

/* Config */
// import config from "../../app/config.json";

/* Components */
import {
  FormButton,
  Modal,
  ControlledSelect,
  ControlledCheckbox,
} from "./Common";
import { Monitor } from "../../features/monitors/monitorApi";
import { Report } from "../../features/reports/reportApi";

/* APIs - Hooks - Utils */
type DetailFormComponentProps = {
  children: React.ReactNode;
};

const DetailFormComponent = ({
  children,
}: DetailFormComponentProps): JSX.Element => {
  return (
    <>
      <div className="bg-gray-100 mx-auto max-w-6xl my-6 py-20 px-12 lg:px-24 shadow-xl mb-24">
        {children}
      </div>
    </>
  );
};

type DetailFormSectionProps = {
  open?: boolean;
  sticky?: boolean;
  children: React.ReactNode;
};

const DetailFormSection = ({
  open,
  sticky,
  children,
}: DetailFormSectionProps): JSX.Element => {
  const sectionClassName = classNames({
    "px-4 py-2 cursor-pointer hover:bg-slate-100": true,
    "sticky top-0 bg-slate-100": sticky,
  });
  return (
    <>
      <details open={open} className={sectionClassName}>
        {children}
      </details>
    </>
  );
};
type DetailFormHeaderProps = {
  sub?: boolean;
  children: React.ReactNode;
};

const DetailFormHeader = ({
  children,
  sub,
}: DetailFormHeaderProps): JSX.Element => {
  const headerSize = sub ? "text-xl" : "text-2xl";
  return (
    <>
      <summary className={`py-4 my-4 text-slate-600 ${headerSize}`}>
        {children}
      </summary>
    </>
  );
};

type DetailFormContentProps = {
  onSubmit?: () => void;
  children: React.ReactNode;
};

const DetailFormContent = ({
  onSubmit,
  children,
}: DetailFormContentProps): JSX.Element => {
  return (
    <>
      <form
        onSubmit={onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col"
      >
        {children}
      </form>
    </>
  );
};

type DetailFormRowProps = {
  children: React.ReactNode;
  bordered?: boolean;
};

const DetailFormRow = ({
  children,
  bordered,
}: DetailFormRowProps): JSX.Element => {
  const formRowClassName = classNames({
    "m-2 flex-wrap md:flex": true,
    "border-dashed border-2 border-slate-300 hover:border-green-400 hover:border-solid p-4 w-full":
      bordered,
  });
  return (
    <>
      <div className={formRowClassName}>{children}</div>
    </>
  );
};

type DetailFormGroupProps = {
  children: React.ReactNode;
  inline?: boolean;
};

const DetailFormGroup = ({
  children,
  inline = false,
}: DetailFormGroupProps): JSX.Element => {
  const groupWidth = inline ? "flex md:w-full" : "md:w-1/2";
  return (
    <>
      <div className={`${groupWidth} px-3 mb-6 md:mb-0`}>{children}</div>
    </>
  );
};

type DetailFormLabelProps = {
  htmlFor?: string;
  children?: React.ReactNode;
  inline?: boolean;
};

const DetailFormLabel = ({
  htmlFor,
  children,
  inline = false,
}: DetailFormLabelProps): JSX.Element => {
  const labelClassName = classNames({
    "py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider":
      true,
    "md:w-5/6": inline,
  });
  return (
    <>
      <label htmlFor={htmlFor} className={labelClassName}>
        {children}
      </label>
    </>
  );
};

interface MonitorDetailFormInputProps extends UseControllerProps<Monitor> {
  type?: "text" | "number";
  disabled?: boolean;
}

const MonitorDetailFormInput = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  type,
  disabled,
}: MonitorDetailFormInputProps): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const inputClassName = classNames({
    "mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500":
      true,
    "border-amber-500 border-2": fieldState.isDirty,
    "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500":
      fieldState.error,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  return (
    <>
      <input
        {...field}
        type={type}
        disabled={disabled}
        placeholder={name.replaceAll("_", " ").toUpperCase()}
        className={inputClassName}
      />
      {fieldState.error ? (
        <p className="mt-2 text-pink-600 text-sm">{fieldState.error.message}</p>
      ) : null}
    </>
  );
};

interface ReportDetailFormInputProps extends UseControllerProps<Report> {
  type?: "text" | "number";
  disabled?: boolean;
}

const ReportDetailFormInput = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  type,
  disabled,
}: ReportDetailFormInputProps): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const inputClassName = classNames({
    "mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500":
      true,
    "border-amber-500 border-2": fieldState.isDirty,
    "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500":
      fieldState.error,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  return (
    <>
      <input
        {...field}
        type={type}
        disabled={disabled}
        placeholder={name.replaceAll("_", " ").toUpperCase()}
        className={inputClassName}
        value={field.value === true ? "True" : "False"}
      />
      {fieldState.error ? (
        <p className="mt-2 text-pink-600 text-sm">{fieldState.error.message}</p>
      ) : null}
    </>
  );
};

DetailFormComponent.Content = DetailFormContent;
DetailFormComponent.Section = DetailFormSection;
DetailFormComponent.Header = DetailFormHeader;
DetailFormComponent.Row = DetailFormRow;
DetailFormComponent.Group = DetailFormGroup;
DetailFormComponent.Label = DetailFormLabel;
DetailFormComponent.MonitorInput = MonitorDetailFormInput;
DetailFormComponent.ReportInput = ReportDetailFormInput;
DetailFormComponent.ControlledSelect = ControlledSelect;
DetailFormComponent.ControlledCheckbox = ControlledCheckbox;
DetailFormComponent.Button = FormButton;
DetailFormComponent.Modal = Modal;

export default DetailFormComponent;
