import React from "react";

type LoginProp = {
  children: React.ReactNode;
};
const Login = ({ children }: LoginProp): JSX.Element => {
  return (
    <>
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        {children}
      </div>
    </>
  );
};

type BrandProp = {
  brandName: string;
  brandLogoSrc: string;
};
const Brand = ({ brandName, brandLogoSrc }: BrandProp): JSX.Element => {
  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src={brandLogoSrc}
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {brandName}
        </h2>
      </div>
    </>
  );
};

type FormProp = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
};
const Form = ({ handleSubmit, children }: FormProp): JSX.Element => {
  return (
    <>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit} method="POST">
          {children}
        </form>
      </div>
    </>
  );
};

type FormRowProp = {
  children?: React.ReactNode;
};
const FormRow = ({ children }: FormRowProp): JSX.Element => {
  return (
    <>
      <div>{children}</div>
    </>
  );
};

type InputFlexContainerProp = {
  children?: React.ReactNode;
};
const InputFlexContainer = ({
  children,
}: InputFlexContainerProp): JSX.Element => {
  return (
    <>
      <div className="flex items-center justify-between">{children}</div>
    </>
  );
};

type InputLabelProp = {
  htmlFor: string;
  label: string;
};
const InputLabel = ({ htmlFor, label }: InputLabelProp): JSX.Element => {
  return (
    <>
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
    </>
  );
};

type InputAsideProp = {
  children?: React.ReactNode;
};
const InputAside = ({ children }: InputAsideProp): JSX.Element => {
  return (
    <>
      <div className="text-sm">{children}</div>
    </>
  );
};

type InputProp = {
  inputType: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean | undefined;
};
const Input = ({ inputType, onChange, required }: InputProp): JSX.Element => {
  return (
    <>
      <div className="mt-2">
        <input
          id={inputType}
          name={inputType}
          type={inputType}
          required={required}
          onChange={onChange}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </>
  );
};

type FormSubmitProp = {
  wording: string;
};
const FormSubmit = ({ wording }: FormSubmitProp): JSX.Element => {
  return (
    <>
      <div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {wording}
        </button>
      </div>
    </>
  );
};

type FormFootnoteProp = {
  footNoteHeader: string;
  children?: React.ReactNode;
};
const FormFootnote = ({
  footNoteHeader,
  children,
}: FormFootnoteProp): JSX.Element => {
  return (
    <>
      <p className="mt-10 text-center text-sm text-gray-500">
        {footNoteHeader}
        {children}
      </p>
    </>
  );
};

Login.Brand = Brand;
Login.Form = Form;
Login.FormRow = FormRow;
Login.InputFlexContainer = InputFlexContainer;
Login.InputLabel = InputLabel;
Login.InputAside = InputAside;
Login.Input = Input;
Login.FormFootnote = FormFootnote;
Login.FormSubmit = FormSubmit;

export default Login;
