import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../user/userApi";

export type UserState = {
  user: User | null;
};

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    unsetUser: () => {
      // TODO: Delete if using user via cookies implemented
      // localStorage.setItem("user", "");
      return initialState;
    },
  },
});

export default userSlice.reducer;

export const { setUser, unsetUser } = userSlice.actions;
