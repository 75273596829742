import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

export const PrivateOutlet = (): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();

  return (
    <>
      {isLoggedIn && user ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} />
      )}
    </>
  );
};
