import React, { useEffect, useState } from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Banner,
  Container,
  LinkButton,
  LoadingScreen,
} from "../../utils/components/Common";
import {
  Table,
  CustomCell,
  // FilterDropdown,
  ICellProps,
} from "../../utils/components/Table";

/* APIs - Hooks - Utils */
import { User } from "../user/userApi";
import { useGetMonitorsQuery } from "./monitorApi";
import { toastQueryError } from "../../utils/toasts";

type MonitorListPanelProps = {
  user?: User;
  isLoggedIn: boolean;
};

const MonitorListPanel = ({
  isLoggedIn,
}: MonitorListPanelProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const { data, isFetching, isLoading, isError, error } = useGetMonitorsQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "Monitor ID",
        accessor: "id",
        Cell: ({ value }: ICellProps) => (
          <CustomCell
            linkTo={`${config.urls.monitors.detail.url}/${value}`}
            value={value}
          />
        ),
      },
      { Header: "URL", accessor: "url" },
      { Header: "Interval", accessor: "interval" },
      { Header: "Periodic Task", accessor: "periodic_task" },
      { Header: "Auth Token", accessor: "auth_token" },
    ],
    []
  );

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {isLoggedIn ? (
        <Container padding="px-16 py-6">
          <LinkButton
            txtColor="text-white"
            bgColor="bg-blue-500"
            bgHover="hover:bg-blue-600"
            linkTo={`${config.urls.monitors.add.url}`}
            target="_blank"
            redirect
          >
            Add Monitor
          </LinkButton>
          {processing ? <LoadingScreen /> : null}
          {data ? <Table columns={columns} data={data.data} /> : null}
        </Container>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default MonitorListPanel;
