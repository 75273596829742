import React from "react";
import { NavLink } from "react-router-dom";
import { User } from "../user/userApi";

type NavProp = {
  children: React.ReactNode;
};
const Nav = ({ children }: NavProp): JSX.Element => {
  return (
    <>
      <nav className="flex items-center justify-between flex-wrap px-6 py-5 bg-gradient-to-r from-cyan-600 via-sky-500 to-teal-500 drop-shadow-xl">
        {children}
      </nav>
    </>
  );
};

type BrandProp = {
  linkTo: string;
  wideWording?: string;
  normalWording?: string;
  children?: React.ReactNode;
};
const Brand = ({
  linkTo,
  wideWording,
  normalWording,
  children,
}: BrandProp): JSX.Element => {
  return (
    <>
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <NavLink to={linkTo}>
          {wideWording ? (
            <span className="text-slate-800 opacity-70 text-m tracking-widest">
              {wideWording}{" "}
            </span>
          ) : null}
          {normalWording ? (
            <span className="text-white opacity-100 text-m">
              {normalWording}
            </span>
          ) : null}
          {children}
        </NavLink>
      </div>
    </>
  );
};

type MenuBtnProp = {
  handleNavMenuOpen: () => void;
  children?: React.ReactNode;
};
const MenuBtn = ({ handleNavMenuOpen, children }: MenuBtnProp): JSX.Element => {
  return (
    <>
      <div className="block lg:hidden">
        <button
          onClick={handleNavMenuOpen}
          className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        {children}
      </div>
    </>
  );
};

type MenuProp = {
  navbarOpen: boolean;
  children?: React.ReactNode;
};
const Menu = ({ navbarOpen, children }: MenuProp): JSX.Element => {
  return (
    <>
      <div
        className={`w-full transistion ${
          navbarOpen ? "block" : "hidden"
        } flex-grow lg:flex lg:items-center lg:w-auto`}
      >
        {children}
      </div>
    </>
  );
};

type LinkCntnrProp = {
  children?: React.ReactNode;
};
const LinkCntnr = ({ children }: LinkCntnrProp): JSX.Element => {
  return (
    <>
      <div className="text-sm lg:flex-grow lg:flex-row lg:flex">{children}</div>
    </>
  );
};

type LinkProp = {
  linkTo: string;
  linkDisplay: string;
  handleFunction?: () => void;
};
const Link = ({
  linkTo,
  linkDisplay,
  handleFunction,
}: LinkProp): JSX.Element => {
  return (
    <>
      <NavLink to={linkTo}>
        <span className="text-center block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
          {handleFunction ? (
            <button onClick={handleFunction}>{linkDisplay}</button>
          ) : (
            <button>{linkDisplay}</button>
          )}
        </span>
      </NavLink>
    </>
  );
};

type ActionCntnrProp = {
  children?: React.ReactNode;
};
const ActionCntnr = ({ children }: ActionCntnrProp): JSX.Element => {
  return (
    <>
      <div className="group inline-block relative">{children}</div>
    </>
  );
};

type ActionDisplayProp = {
  user: User | null;
  children?: React.ReactNode;
};
const ActionDisplay = ({ user, children }: ActionDisplayProp): JSX.Element => {
  return (
    <>
      <button
        type="button"
        className="flex items-center transition rounded-lg group shrink-0 mt-4 lg:mt-0 text-teal-200 hover:text-white mr-4"
      >
        <div className="inline-flex overflow-hidden relative justify-center items-center w-8 h-8 object-cover bg-gray-100 rounded-full dark:bg-gray-600">
          <span className="font-medium text-gray-600 dark:text-gray-300">
            {user && user.first_name
              ? user.first_name.charAt(0)
              : user && user.username
                ? user.username.charAt(0)
                : null}
          </span>
        </div>

        <p className="hidden ml-2 text-xs text-left sm:block">
          <strong className="block font-medium">
            {user && user.first_name
              ? user.first_name
              : user && user.username
                ? user.username
                : null}
          </strong>

          <span className="text-gray-500">
            {user && user.email ? user.email : null}
          </span>
        </p>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="hidden w-5 h-5 ml-4 text-gray-500 transition sm:block group-hover:text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        {children}
      </button>
    </>
  );
};

type ActionLinkProp = {
  linkTo: string;
  linkDisplay: string;
  handleFunction?: () => void;
  firstItem?: boolean;
  lastItem?: boolean;
};
const ActionLink = ({
  linkTo,
  linkDisplay,
  handleFunction,
  firstItem,
  lastItem,
}: ActionLinkProp): JSX.Element => {
  return (
    <>
      <NavLink to={linkTo}>
        <span
          className={`${
            firstItem ? "rounded-t" : lastItem ? "rounded-b" : null
          } bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`}
        >
          {handleFunction ? (
            <button onClick={handleFunction}>{linkDisplay}</button>
          ) : (
            <button>{linkDisplay}</button>
          )}
        </span>
      </NavLink>
    </>
  );
};

type ActionDropdownProp = {
  children?: React.ReactNode;
};
const ActionDropdown = ({ children }: ActionDropdownProp): JSX.Element => {
  return (
    <>
      <ul className="absolute hidden text-gray-700 pt-1 group-hover:block">
        {children}
      </ul>
    </>
  );
};

Nav.Brand = Brand;
Nav.MenuBtn = MenuBtn;
Nav.Menu = Menu;
Nav.LinkCntnr = LinkCntnr;
Nav.Link = Link;
Nav.ActionCntnr = ActionCntnr;
Nav.ActionDisplay = ActionDisplay;
Nav.ActionDropdown = ActionDropdown;
Nav.ActionLink = ActionLink;

export default Nav;
