import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

/* Config */
import config from "../../app/config.json";

/* Components */
import { HeroSection } from "../../utils/components/Common";

/* APIs - Utils */
import { useLoginMutation } from "../auth/authApi";
import { AuthLoginRequest } from "../auth/authApi";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";

const LoginCallback = (): JSX.Element => {
  let didLogin = false;
  // onlyl using searchParams from useSearchParams hook.
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Read callback query string from identity provider
  const idProvider = searchParams.get("provider");
  const cbCode = searchParams.get("code");
  const cbState = searchParams.get("state");
  const loginDetails: AuthLoginRequest = {
    code: cbCode,
    state: cbState,
    url: idProvider + "/",
  };

  const [login, { isLoading, isSuccess, isError, error, data }] =
    useLoginMutation();

  useEffect(() => {
    if (!didLogin) {
      didLogin = true;
      login(loginDetails);
    }
  }, [cbCode, idProvider]);

  useEffect(() => {
    if (isSuccess && data?.user) {
      toastQuerySuccess("Login successful..");
      navigate(config.urls.user.workspace.url);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading]);

  return (
    <>
      <HeroSection
        heading={`${
          isLoading ? "Logging In.." : isError ? "Error in logging in.." : null
        }`}
      />
    </>
  );
};

export default LoginCallback;
