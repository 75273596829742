import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCSRFToken } from "./csrf";

export type AuthState = {
  isLoggedIn: boolean | false;
  access_token: string | null;
  refresh_token: string | null;
  csrf_token: string | null;
};

export const initialState: AuthState = {
  isLoggedIn: false,
  access_token: null,
  refresh_token: null,
  csrf_token: null,
};

type DjangoMessageType = {
  [key: string]: string;
};

export type DjangoErrorDataType = {
  detail: string;
  code?: string;
  messsages?: DjangoMessageType[];
};

/* Using User defined type guard to check for Django Api Error return on api calls */
export function isDjangoErrorResponse(
  obj: unknown
): obj is DjangoErrorDataType {
  return typeof obj === "object" && obj !== null && "detail" in obj;
}

type RefreshTokenResponse = {
  access: string;
  access_token_expiration: string;
};

/* Using User defined type guard to check for PayloadAction return on refreshCredentials */
function isRefreshTokenResponse(obj: unknown): obj is RefreshTokenResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "access" in obj &&
    "access_token_expiration" in obj
  );
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => {
      const csrfToken = getCSRFToken();
      state.isLoggedIn = true;
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      if (csrfToken) {
        state.csrf_token = csrfToken;
      }
    },
    unsetCredentials: () => {
      return initialState;
    },
    refreshCredentials: (state, action: PayloadAction<unknown>) => {
      if (isRefreshTokenResponse(action.payload)) {
        state.access_token = action.payload.access;
      }
    },
  },
});

export default authSlice.reducer;

export const { setCredentials, unsetCredentials, refreshCredentials } =
  authSlice.actions;
