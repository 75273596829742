import { History, Transition } from "history";
import { useCallback, useContext, useEffect } from "react";
import { Navigator } from "react-router";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

/* 
Workaround for missing react-router-dom hooks usePrompt/useBlocker
https://stackoverflow.com/a/71587163/8046487 
*/
type ExtendNavigator = Navigator & Pick<History, "block">;
const useBlocker = (blocker: (tx: Transition) => void, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

type PromptProps = {
  when: boolean;
  message: string;
};

const usePrompt = ({ message, when }: PromptProps) => {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
};

export { usePrompt };
