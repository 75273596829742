import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  Column,
  useGlobalFilter,
  useFilters,
  FilterProps,
} from "react-table";
import { IoSearch } from "react-icons/io5";

/* Config */
import config from "../../app/config.json";

/* Components */
import { Container, IconType, LinkButton } from "./Common";

/* APIs - Hooks - Utils */
import { Monitor } from "../../features/monitors/monitorApi";
import { Report } from "../../features/reports/reportApi";

export interface ICellProps {
  value: string;
}

type CustomCellProps = {
  value: string;
  linkTo: string;
};

export const CustomCell = ({ value, linkTo }: CustomCellProps): JSX.Element => {
  return (
    <LinkButton
      bgColor=""
      bgHover=""
      txtColor="text-slate-400 hover:text-slate-600"
      linkTo={linkTo}
    >
      {value}
    </LinkButton>
  );
};

type FilterIconProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: IconType;
};

export const FilterInput = ({
  icon,
  onChange,
}: FilterIconProps): JSX.Element => {
  return (
    <>
      <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
        <span className="absolute ml-3 pointer-events-none">
          {icon
            ? React.createElement(icon, {
                width: 12,
                height: 12,
                color: "#808080",
              })
            : null}
        </span>
        <input
          type="text"
          name="filter"
          placeholder="Search"
          className="bg-white placeholder-gray-500 placeholder-opacity-25 placeholder:italic border border-slate-300 rounded-full py-2 pl-10 pr-4 focus:outline-none"
          onChange={onChange}
        />
      </div>
    </>
  );
};

export const FilterDropdown = <T extends Record<string, unknown>>({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<T>): JSX.Element => {
  const options = React.useMemo(() => {
    const options = new Set<string>();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...Array.from(options.values())];
  }, [id, preFilteredRows]);

  return (
    <>
      <select
        name={id}
        value={filterValue}
        className="block w-xl bg-gray-200 border border-gray-200 text-gray-700 py-1 mx-auto rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
};

type TableProps = {
  columns: Column[];
  data: Monitor[] | Report[];
};

export const Table = ({ columns, data }: TableProps): JSX.Element => {
  /* Required for useFilters hook, set to blank for any other column */
  const defaultColumn = useMemo(
    () => ({
      Filter: "",
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: config.table.initialState.sorting.fieldName,
            desc: config.table.initialState.sorting.isDesc,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  );

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  return (
    <Container padding="px-16 py-6">
      <div className="rounded-full text-xs font-bold text-white bg-blue-500 py-1 px-2 my-1 float-right">
        n={rows.length}
      </div>

      <FilterInput icon={IoSearch} onChange={handleFilterInputChange} />

      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => {
                    const { key, ...restHeaderGroupProps } =
                      headerGroup.getHeaderGroupProps();
                    return (
                      <tr key={key} {...restHeaderGroupProps}>
                        {headerGroup.headers.map((column) => {
                          const { key, ...restColumn } = column.getHeaderProps(
                            column.getSortByToggleProps()
                          );
                          return (
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              key={key}
                              {...restColumn}
                            >
                              <span>
                                {column.render("Header")}
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " ⬇️"
                                    : " ⬆️"
                                  : ""}
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </span>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps}
                >
                  {rows.map((row) => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();
                    return (
                      <tr key={key} {...restRowProps}>
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();
                          return (
                            <td
                              className="px-6 py-4 whitespace-nowrap"
                              key={key}
                              {...restCellProps}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
