import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

/* Config */
import config from "./app/config.json";

/* Views */
import Landing from "./features/landing/Landing";
import Workspace from "./features/workspace/Workspace";
import LoginCallback from "./features/auth/LoginCallback";
import MonitorDetail from "./features/monitors/MonitorDetail";
import MonitorAdd from "./features/monitors/MonitorAdd";

/* Components */
import AppNavbar from "./features/navbar/Navbar";

/* APIs - Utils*/
import { PrivateOutlet } from "./utils/components/PrivateOutlet";
import { useCsrfPingMutation } from "./features/auth/authApi";
import { toastQueryError } from "./utils/toasts";

function App() {
  let didSendPing = false;
  const [csrfPing, { isError, error }] = useCsrfPingMutation();

  useEffect(() => {
    if (!didSendPing) {
      csrfPing();
      didSendPing = true;
    }
  }, []);

  useEffect(() => {
    if (error) {
      toastQueryError(error);
    }
  }, [isError, error]);

  return (
    <>
      <ToastContainer />
      <AppNavbar />
      <Routes>
        <Route path={config.urls.home.url} element={<Landing />} />
        <Route
          path={config.urls.auth.loginCallback.url}
          element={<LoginCallback />}
        />
        <Route
          path={config.urls.user.workspace.url}
          element={<PrivateOutlet />}
        >
          <Route index element={<Workspace />} />
        </Route>
        <Route
          path={`${config.urls.monitors.detail.url}/:pk`}
          element={<PrivateOutlet />}
        >
          <Route index element={<MonitorDetail />} />
        </Route>
        <Route
          path={`${config.urls.monitors.add.url}/`}
          element={<PrivateOutlet />}
        >
          <Route index element={<MonitorAdd />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
