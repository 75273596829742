import React from "react";

/* Config */

/* Components */

/* APIs - Utils*/
import { useAppSelector } from "../../app/hooks";
import LoginForm from "../auth/LoginForm";

const Landing = (): JSX.Element => {
  const user = useAppSelector((state) => state.user.user);

  return <>{!user ? <LoginForm /> : null}</>;
};

export default Landing;
