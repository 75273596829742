import React, { useState, useEffect } from "react";
import { object, string, number } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Components */
import MonitorDetailFormComponent from "../../utils/components/DetailForm";
import { LoadingScreen } from "../../utils/components/Common";

/* APIs - Utils */
import { Monitor, useAddMonitorMutation } from "./monitorApi";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";
import { usePrompt } from "../../utils/hooks";

const REGEX_URL = /^(http|https):\/\/[^ "]+$/;

const schema = object().shape({
  id: number().notRequired().nullable(),
  url: string()
    .matches(REGEX_URL, {
      message:
        "The URL is not valid. Please enter a valid URL starting with http:// or https://",
      excludeEmptyString: true,
    })
    .required(),
  interval: number().required(),
  periodic_task: number().notRequired().nullable(),
  auth_token: string().nullable(),
});

type MonitorAddFormProps = {
  setAddMonitorModalOpen: (open: boolean) => void;
  setAddedMonitor: (Monitor: Monitor | undefined) => void;
};
export const MonitorAddForm = ({
  setAddMonitorModalOpen,
  setAddedMonitor,
}: MonitorAddFormProps): JSX.Element => {
  const newMonitor: Partial<Monitor> = {
    id: undefined,
    url: "",
    interval: undefined,
    periodic_task: undefined,
    auth_token: "",
  };
  const [updatedData, setUpdatedData] = useState(newMonitor);
  const [addMonitor, { isLoading, isSuccess, isError, error }] =
    useAddMonitorMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    getValues,
    control,
    reset,
    formState: { isDirty },
  } = useForm<Monitor>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (updatedData: Monitor) => {
    addMonitor(updatedData)
      .unwrap()
      .then((returnedData) => {
        setUpdatedData(returnedData);
        setModalOpen(false);
        setAddMonitorModalOpen(false);
        setAddedMonitor(returnedData);
        toastQuerySuccess("Successfully added Monitor..");
      })
      .catch((returnedError) => toastQueryError(returnedError));
  };

  useEffect(() => {
    setModalOpen(false);
    if (isSuccess) {
      reset(updatedData);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isSuccess, isError, error]);

  usePrompt({
    message:
      "Unsaved changes may be lost.\nAre you sure you want to leave the page?",
    when: isDirty,
  });

  return (
    <>
      {isLoading ? <LoadingScreen blackOut /> : null}
      <MonitorDetailFormComponent>
        <MonitorDetailFormComponent.Content>
          {modalOpen ? (
            <MonitorDetailFormComponent.Modal
              setModalOpen={setModalOpen}
              header="Please verify to continue"
              body="You are about to add a Monitor Entry, click CONFIRM to continue"
            >
              <MonitorDetailFormComponent.Button
                type="button"
                txtColor="text-red-500"
                bgColor="bg-transparent"
                onClick={() => setModalOpen(false)}
              >
                CLOSE
              </MonitorDetailFormComponent.Button>
              <MonitorDetailFormComponent.Button
                type="button"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
                onClick={() => onSubmit(getValues())}
              >
                CONFIRM
              </MonitorDetailFormComponent.Button>
            </MonitorDetailFormComponent.Modal>
          ) : null}

          <MonitorDetailFormComponent.Header>
            Monitor Add
          </MonitorDetailFormComponent.Header>
          <MonitorDetailFormComponent.Row>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                URL
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="url"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                Interval
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="interval"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
            <MonitorDetailFormComponent.Group>
              <MonitorDetailFormComponent.Label>
                Auth Token
              </MonitorDetailFormComponent.Label>
              <MonitorDetailFormComponent.MonitorInput
                control={control}
                name="auth_token"
                type="text"
              />
            </MonitorDetailFormComponent.Group>
          </MonitorDetailFormComponent.Row>
          <MonitorDetailFormComponent.Row>
            <MonitorDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-blue-500"
              bgHover="hover:bg-blue-600"
              onClick={() => setModalOpen(true)}
            >
              Add Monitor
            </MonitorDetailFormComponent.Button>
            <MonitorDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-slate-500"
              bgHover="hover:bg-slate-600"
              onClick={() => reset(updatedData)}
            >
              Reset
            </MonitorDetailFormComponent.Button>
          </MonitorDetailFormComponent.Row>
        </MonitorDetailFormComponent.Content>
      </MonitorDetailFormComponent>
    </>
  );
};
