import { setCredentials, unsetCredentials } from "./authSlice";
import { unsetUser, setUser } from "../user/userSlice";
import { User } from "../user/userApi";
import { getCSRFToken } from "./csrf";
import { apiSlice } from "../api/apiSlice";

export interface AuthLoginResponse {
  user: User;
  access_token: string;
  refresh_token: string;
  csrf_token: string;
  isLoggedIn: boolean;
}
export interface AuthLoginRequest {
  code: string | null;
  state: string | null;
  url: string;
}
export interface AuthMiddlewareLoginRequest {
  username: string;
  password: string;
}
export interface AuthLogoutRequest {
  /* 
  dj-rest-auth uses this keyword in looking up the 
  `refresh token` in the request data to blacklist.
  */
  refresh: string | null;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    csrfPing: builder.mutation<void, void>({
      query() {
        return {
          url: "ping",
          method: "GET",
          headers: {
            "X-CSRFToken": getCSRFToken(),
          },
        };
      },
    }),
    login: builder.mutation<AuthLoginResponse, AuthLoginRequest>({
      query({ code, state, url }) {
        return {
          url: "auth/" + url,
          method: "POST",
          body: { code: code, state: state },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
          dispatch(setUser(data.user));
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
    logout: builder.mutation<void, AuthLogoutRequest>({
      query({ refresh }) {
        return {
          url: "auth/logout/",
          method: "POST",
          body: { refresh: refresh },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(unsetCredentials());
          dispatch(unsetUser());
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
    middlewareLogin: builder.mutation<
      AuthLoginResponse,
      AuthMiddlewareLoginRequest
    >({
      query({ username, password }) {
        return {
          url: "auth/login/",
          method: "POST",
          body: { username: username, password: password },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
          dispatch(setUser(data.user));
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
  }),
});

export const {
  useCsrfPingMutation,
  useLoginMutation,
  useMiddlewareLoginMutation,
  useLogoutMutation,
} = extendedApiSlice;
